import React, { useState, useEffect } from "react";
import Homepage from "../components/Homepage";

import axios from "axios";
import { BallTriangle } from "react-loader-spinner";

export default function HomePage() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // To avoid setting state on unmounted component
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await axios.get("https://amirrealtor-react-api.com", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response.data);
        if (isMounted) {
          setData(response.data.homes);
          setLoading(false); // Set loading to false after data is fetched
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message); // Set error message if fetching fails
          setLoading(false);
        }
        console.error(err);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []); // Only run on component mount

  if (loading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen bg-black">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#FF5F01"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full h-screen bg-black">
      <div className="w-fit">
        <h1 className="text-3xl font-bold text-amir-100 text-center">
          Site Under Maintenance!!!
        </h1>
      </div>
    </div>
  );
}
