import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";

import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import Services from "./pages/Services";
import About from "./pages/About";
import AllHomes from "./pages/AllHomes";
import HomeDetailsPage from "./pages/HomeDetailsPage";
import Contact from "./pages/Contact";
import HomeRent from "./pages/HomeRent";
import SearchResults from "./pages/SearchResults";
import HomeRentDetails from "./pages/HomeRentDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
      </Route>
    </Routes>
  );
}

export default App;
